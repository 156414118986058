import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { getCourseNavigation } from "../../../../../store/courses"

import LayoutCourse from "../../../../../layouts/course"

import Box from "../../../../../components/box"
import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import FloatContainer from "../../../../../components/float-container"
import Heading from "../../../../../components/heading"
import Image from "../../../../../components/image"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "reiseerinnerung" })

  const data = useStaticQuery(graphql`
    query KurseReisenQuery {
      karte: file(
        relativePath: {
          eq: "dauerausstellung/07-harte-fuegungen/karte-chandler.jpg"
        }
      ) {
        ...mediumImage
      }
    }
  `)

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext to="/kurse/reiseerinnerung/01-suedfrankreich/reisen/wanderer" />
      }
    >
      <Seo title="Das Reisen zu Hölderlins Zeit" />
      <Stack>
        <Heading as="h2" level={2}>
          Das Reisen zu Hölderlins Zeit
        </Heading>
        <Paragraph>
          Hölderlin war mit der Reiseliteratur seiner Zeit gut vertraut. Ebenso
          wie Schiller las auch er bereits auf der Lateinschule Homers antike
          Erzählung über die abenteuerliche Irrfahrt, die Odysseus zurücklegen
          muss, bevor er in seine Heimatstadt Ithaka zurück darf. Schon damals
          begeisterte sich Hölderlins für die griechische Antike und ihre
          Literatur. In seinem Griechenland-Roman ›Hyperion‹ finden sich
          zahlreiche Verweise auf Homer, die griechischen Tempelbauwerke und die
          einstige Hochkultur, von der im Roman allerdings nur noch Ruinen
          erhalten sind.
        </Paragraph>
        <Box>
          <FloatContainer align="right" width="40%">
            <Image
              image={data.karte.childImageSharp.gatsbyImageData}
              alt="Eine alte Landkarte zeigt den Isthmus von Korinth"
              caption="Karte aus Richard Chandlers ›Reisen in Griechenland‹ "
            />
          </FloatContainer>
          <Paragraph>
            Aus Richard Chandlers Bericht über seine Reise durch Griechenland in
            den 1760er-Jahren hat Hölderlin ganze Passagen fast wörtlich
            übernommen. Das Buch des Engländers ermöglichte ihm auch die
            geografische Orientierung: So sieht Hyperion den Isthmus von Korinth
            zu Beginn des Romans, als ob er auf eine der beigefügten Landkarten
            blicken würde. Doch obgleich er die Landschaftsbeschreibungen dieses
            Romans allein durch das Studium von Landkarten und
            Reisebeschrebungen gewann, war Hölderlin durchaus kein reiner
            Schreibtischreisender. Schon während seines Studiums in Tübingen zog
            es ihn für eine Wanderung in die Schweiz, auf die Spuren von Wilhelm
            Tell.
          </Paragraph>
        </Box>
        <Paragraph>
          Anders als Schiller, der den schweizer Freiheitskämpfer später zur
          legendären Figur seines letzten Dramas machte, ohne dafür auch nur
          einen Fuß in die Schweiz zu setzen, ist Hölderlin Tells
          Gebirgswanderung zur Stelle des Rüttli-Schwurs selbst nachgelaufen und
          hat diese Reise ins »Land der göttlichen Freiheit« in seinem Gedicht
          ›Kanton Schweiz‹ festgehalten.
        </Paragraph>
      </Stack>
    </LayoutCourse>
  )
}

export default Page
